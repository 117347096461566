import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

import { HeroMainPage } from "../components/sections/heroSection.js"
import Showcase from "../components/sections/showcaseSection.js"
import {
  ValuePropLeft,
  ValuePropRight,
  ValuePropsX3NoDescrip,
  SwipeSection,
} from "../components/sections/valuePropSection.js"
import { HorizontalSection } from "../components/sections/sections.js"
import { SectionTitle } from "../components/typography.js"
import NavigateToOtherPageButtons from "../components/molecules/ButtonFooter.js"

import Consult from "../images/conversation.svg"
import Study1 from "../images/exams.svg"
import Collab from "../images/live_collaboration.svg"
import Interview from "../images/ideas_flow.svg"

import CheckBoxes from "../images/check_boxes.svg"
import SuperWoman from "../images/superwoman.svg"
import PersonalTraining from "../images/personal_training.svg"
import Update from "../images/update.svg"
import OrderConfirmed from "../images/order_confirmed.svg"

const IndexPage = ({ data }) => {
  let emphPinkStyle = { color: "#F50057" }
  let sectionTitleStyle = {
    marginBottom: "0",
    marginTop: "0px",
    paddingTop: "100px",
    paddingBottom: "50px",
    backgroundColor: "rgb(247,247,247)",
  }
  let heroMainPageLinks = [
    { label: "MCAT", link: "/mcat" },
    { label: "CASPer", link: "/casper" },
    { label: "Interview Prep.", link: "/interview_preparation" },
    { label: "Med. Applications", link: "/editing_applications" },
    { label: "LSAT", link: "/lsat" },
    { label: "GMAT", link: "/gmat" },
    { label: "GRE", link: "/gre" },
    { label: "Career Planning", link: "/career_planning" },
  ]
  let horizontalSectionAsides = [
    {
      title: "Almost Guaranteed Success",
      src: OrderConfirmed,
      alt: "Notes Image",
      description: [
        `This sounds `,
        <span style={{ color: "#F50057" }}>
          <em>crazy</em>
        </span>,
        ` but it's `,
        <span style={{ color: "#F50057" }}>
          <em>true!</em>
        </span>,
        ` As long as you're studying hard, following our recommendations, and talking to us consistently - there's no chance you're going to fail. `,
      ],
    },
    {
      title: `The #LEGGO Approach`,
      src: CheckBoxes,
      alt: `Schedule Image`,
      description: [
        `With our '#LEGGO!' `,
        <span style={{ color: "#F50057" }}>(let's go!)</span>,
        ` attitude, we kick aside frustration and negativity, and bring out an upbeat and optimistic mindset that kickstarts your momentum, and gets you in go-mode.`,
      ],
    },
    {
      title: "Boundless Butt-Kicking 🤣",
      src: PersonalTraining,
      alt: "Review Sheet Image",
      description: [
        `Everyone procrastinates - there's nothing wrong with that! So, when you need it, we'll kick your butt and keep you accountable and on track. It's surprisingly effective!`,
      ],
    },
    {
      title: "Learning Hacked",
      src: PersonalTraining,
      alt: "Review Sheet Image",
      description: [
        `We make you learn in insane ways, that'll initially make your jaw drop. You'll say, "it's impossible" at first and then within five minutes (or a day), you'll do a double-take. Fo sho!`,
      ],
    },
    {
      title: "(Over-)Committed Instructors",
      src: SuperWoman,
      alt: "Review Sheet Image",
      description: `Honestly, we're a bit out of our minds and we love helping you more than we should (We're funny too!). We watch over you, talk to you a ton, and tell you stuff that's sometimes life-changing. It's magical. `,
    },
    {
      title: "Ridiculous Incentives",
      src: Update,
      alt: "Review Sheet Image",
      description: `We don't do this all the time, but if we see that you're working insanely hard, we might offer you work with us on a paper, or a project, or find you a job, or write you a reference letter. You can't just ask for it; you have to earn it.`,
    },
  ]
  let valueProp3xDescripStyle = {
    margin: "0 auto",
    paddingTop: "0px",
    paddingBottom: "200px",
  }
  let valueProp3xAsides = [
    {
      title: [
        // <span style={{ color: "#F50057" }}>90%</span>,
        " Success rate",
      ],

      metric: "90%",
    },
    {
      title: [
        // <span style={{ color: "#F50057" }}>50+</span>,
        "Professional-school admissions experience",
      ],

      metric: "80+",
    },
    {
      title: [
        // <span style={{ color: "#F50057" }}>25K+</span>,
        " Students helped",
      ],

      metric: "25K+",
    },
  ]

  //Section 1: Standardized Test prep.
  let pageLinkStyles = {
    backgroundColor: "black",
    color: "white",
    fontWeight: "600",
    margin: "16px auto 0px 0px",
    padding: "8px 12px",
    position: "relative",
  }
  let testPrepLinks = [
    {
      label: "MCAT",
      link: "/mcat",
      style: pageLinkStyles,
    },
    {
      label: "CASPer",
      link: "/casper",
      style: pageLinkStyles,
    },
    { label: "LSAT", link: "/lsat", style: pageLinkStyles },
    { label: "GMAT", link: "/gmat", style: pageLinkStyles },
    { label: "GRE", link: "/gre", style: pageLinkStyles },
  ]
  let standardizedTestPrepDescription = [
    `We have been teaching students cumulatively for 10+ years. We teach bottom-up (you don't need to know anything!), use tons of pop culture, looove connecting concepts, and make learning fun. Don't be intimidated but these tests -- trust us, we'll show how easy it is to do well on them!`,
    <br />,
    <br />,
    `Check 'em out:`,
    <NavigateToOtherPageButtons
      width={"100%"}
      align={"start"}
      ctaPrimary={testPrepLinks}
      noPadding={true}
    />,
  ]
  //Section 2: Career Planning
  let careerPlanningStyle = {
    backgroundColor: "black",
    color: "white",
    fontWeight: "600",
    margin: "16px auto 0px 0px",
    padding: "8px 12px",
    position: "relative",
  }
  let careerPlanningDescrip = [
    "Personally and professionally, we know how it feels to be stuck. Whether you don't know what to do to reach your goals, or you're not sure what to do later in life at all, don't worry -- we've got you! Our unconstrained approach focusses on building on your interests, having fun with what you do, and having you decide a career that you'll love.",
    <NavigateToOtherPageButtons
      width={"100%"}
      align={"start"}
      ctaPrimary={[
        {
          label: "Take me to careers",
          link: "/career_planing",
          style: careerPlanningStyle,
        },
      ]}
      noPadding={true}
    />,
  ]
  //Section 3: App editint
  let appEditDescrip = [
    `Ask anyone, we know how to prepare you for interviews. Sometimes people don't know what to say in their apps., sometimes they don't know how to word them, and sometimes they need a professional opinion from people who've placed countless students into professional schools; we do it all! We bring your personality into your apps., so you can feel that you've killed it!`,
    <NavigateToOtherPageButtons
      width={"100%"}
      align={"start"}
      ctaPrimary={[
        {
          label: "Take me to apps.",
          link: "/editing_applications",
          style: careerPlanningStyle,
        },
      ]}
      noPadding={true}
    />,
  ]
  let interviewDescrip = [
    `We train you how to be confident, and to have genuine conversations with your interviewers, so you can leave a lasting impression! Our students know what to say, how to say it, and when to say it, and they bring a level of pizzaz that almost guarantees that they get many offers! It's a pretty dope when our students choose their schools instead of the schools choosing them ;)`,
    <NavigateToOtherPageButtons
      width={"100%"}
      align={"start"}
      ctaPrimary={[
        {
          label: "Take me to interviews",
          link: "/editing_applications",
          style: careerPlanningStyle,
        },
      ]}
      noPadding={true}
    />,
  ]
  return (
    <Layout>
      <SEO title="99point9 Homepage" />
      <HeroMainPage
        center="center"
        title="We transform ambitions into careers."
        ctaPrimary={heroMainPageLinks}
      >
        With <span style={emphPinkStyle}> 99point9,</span>
        <span>
          {" "}
          students motivated to succeed, through our unconventional methods,
        </span>
        <span style={emphPinkStyle}> achieve.</span>
      </HeroMainPage>
      <Showcase />
      <HorizontalSection>
        <SwipeSection
          sectionColor={"rgb(245,245,245)"}
          wide
          title="What's somewhat good about us?"
          asides={horizontalSectionAsides}
        />
      </HorizontalSection>
      <ValuePropsX3NoDescrip
        float={"center"}
        wide
        style={valueProp3xDescripStyle}
        asides={valueProp3xAsides}
      />
      <>
        <SectionTitle center="center" style={sectionTitleStyle}>
          What can we prepare you for?
        </SectionTitle>
        <ValuePropLeft
          wide={true}
          sectionColor={"rgb(247,247,247)"}
          caption="Personalized"
          title="Standardized-Tests Preparation"
          description={standardizedTestPrepDescription}
          source={Study1}
        />
        <ValuePropRight
          wide={true}
          sectionColor={"rgb(247,247,247)"}
          caption="Flawless"
          title="Career Planning"
          description={careerPlanningDescrip}
          source={Consult}
        />

        <ValuePropLeft
          wide={true}
          caption="Top-notch"
          sectionColor={"rgb(247,247,247)"}
          title={"App. Consulting \n & Editing"}
          description={appEditDescrip}
          source={Collab}
        />
        <ValuePropRight
          wide={true}
          sectionColor={"rgb(247,247,247)"}
          caption="Out-of-this-world"
          title="Interview Preparation"
          description={interviewDescrip}
          source={Interview}
        />
      </>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    sohaibImage: file(relativePath: { eq: "myPicture.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
